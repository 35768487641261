import React, { useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Labeled,
  required,
  useRecordContext,
  FormDataConsumer,
  email,
  number,
} from 'react-admin';
import { ROLES } from '../Config/AuthProvider';
import BackButton from '../Components/BackButton';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';
import { useFormContext } from 'react-hook-form';

const UsersEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? ` User: ${record.username}` : ''}</span>;
};

const CustomToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmTitle={`Delete Student: ${record.username}`}
        confirmContent={`Are you sure you want to delete ${record.username}?`}
        variant="contained"
        sx={{
          color: 'white',
          backgroundColor: 'red',
          padding: '6px 11px',
          '&:hover': {
            backgroundColor: '#0066CC',
          },
        }}
      />
    </Toolbar>
  );
};

const isRoleStudent = (role) => {
  return role === 'STUDENT' ? true : false;
};

// regex to validate email format
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// regex to validate (123) 456-7890 format (not needed; auto-formatting instead)
const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

// format phone number with parentheses and dash
const formatPhoneNumber = (value) => {
  if (!value) return '';
  const numericValue = value.replace(/\D/g, '');
  if (numericValue.length <= 3) {
    return `(${numericValue}`;
  } else if (numericValue.length <= 6) {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
  } else {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  }
};

// custom phone number input
const PhoneNumberInput = () => {
  const formContext = useFormContext();

  const handlePhoneNumberChange = (event) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    formContext.setValue('phoneNumber', formattedValue);
  };

  return (
    <TextInput
      label="Phone Number"
      source="phoneNumber"
      validate={required()}
      onChange={handlePhoneNumberChange}
    />
  );
};

const validateUsersEdit = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Must be a valid email';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone Number is required';
  } else if (values.phoneNumber?.length < 14) {
    errors.phoneNumber = 'Phone Number contains too few digits';
  }
  return errors;
};

const UsersEdit = () => {
  return (
    <>
      <BackButton label="Return to Users List" to="/users" />
      <Edit title={<UsersEditTitle />} mutationMode="pessimistic" undoable={false}>
        <SimpleForm toolbar={<CustomToolbar />}
          mode="onBlur"
          reValidateMode="onBlur"
          validate={validateUsersEdit}
        >
          <TextInput source="username" validate={[required()]} />
          <Labeled label="CAC Login Enabled">
            <TextField sx={{ pb: 1 }} source="hasEdipiHashYesNo" />
          </Labeled>

          {/* <SelectInput
            label="Role"
            source="role.roleId"
            choices={ROLES}
            optionValue="roleId"
            validate={[required()]}
          /> */}

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <SelectInput
                  label="Role"
                  source="role.roleId"
                  choices={ROLES}
                  optionValue="roleId"
                  validate={[required()]}
                // disabled={isRoleStudent(formData.role.name)}
                />
              )}
            </FormDataConsumer>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role.roleId === 2 && (
                  <SelectInput
                    label="Track"
                    source="track"
                    choices={INSTRUCTOR_TRACKS}
                    optionValue="name"
                    validate={[required()]}
                  // disabled={isRoleStudent(formData.role.name)}
                  />
                )
              }
            </FormDataConsumer>
          </div>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                source="organization.id"
                reference="organizations"
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput
                  label="Organization"
                  optionText="name"
                  disabled={isRoleStudent(formData.role.name)}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <Labeled label="AppUser">
            <TextField sx={{ pb: 1 }} source="appUser.appUserAlias" />
          </Labeled>

          <ReferenceInput source="regStatus.lookupCodeId" reference="registrationstatuses">
            <SelectInput label="Registration Status" optionText="name" validate={required()} />
          </ReferenceInput>

          <ReferenceInput source="userStatus.lookupCodeId" reference="userstatuses">
            <SelectInput label="User Status" optionText="name" validate={required()} />
          </ReferenceInput>

          <TextInput source="email" validate={[required()]} />

          <PhoneNumberInput />

          <Labeled label="Approved By">
            <TextField sx={{ pb: 1 }} source="approvedBy" />
          </Labeled>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default UsersEdit;
