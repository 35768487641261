import React from 'react';
import { Layout, AppBar, UserMenu, usePermissions, useGetList, Logout } from 'react-admin';
import { Typography, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import TrainIcon from '@mui/icons-material/Train';
import { TveMenu } from './TveMenu';
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';
import { styled } from '@mui/material/styles';
import StoredUser from '../System/StoredUser';
import { CircularProgress } from '@mui/material';

const ShowRole = () => {
  const { permissions } = usePermissions();
  return (
    <MenuItem>
      <ListItemIcon sx={{ minWidth: 5 }}>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText>{permissions}</ListItemText>
    </MenuItem>
  );
};

const ShowTrack = () => {
  const appuserid = new StoredUser().load().appUser.appUserId;

  const { data, isLoading, error } = useGetList('users', {
    pagination: { page: 1, perPage: 4000 },
  });

  if (isLoading) return <CircularProgress />;
  if (error) return <div>error</div>

  const matchingUser = data.find(user => user.appUser?.appUserId === appuserid);

  const track = matchingUser?.track;
  // students and admins won't have a track, so don't show this part of the profile for them
  if (track === undefined || track === null || track === '') return false;

  return (
    <MenuItem>
      <ListItemIcon sx={{ minWidth: 5 }}>
        <TrainIcon />
      </ListItemIcon>
      <ListItemText>{track}</ListItemText>
    </MenuItem>
  );
};

const ShowEnvironment = () => {
  return (
    <MenuItem>
      <ListItemIcon sx={{ minWidth: 5 }}>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{CANES_TVE_ENV}</ListItemText>
    </MenuItem>
  );
};

const TveUserMenu = (props) => (
  <UserMenu {...props}>
    <ShowRole />
    <ShowTrack />
    <ShowEnvironment />
    <Logout />
  </UserMenu>
);

const TopStripe = styled('div')`
  background-color: #008000;
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const TveAppBar = (props) => (
  <AppBar sx={{ color: 'white', backgroundColor: 'black' }} {...props} userMenu={<TveUserMenu />}>
    <TopStripe />
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
  </AppBar>
);

export const TveLayout = (props) => <Layout {...props} appBar={TveAppBar} menu={TveMenu} />;
