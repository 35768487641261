import React from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '365px auto',
  gridGap: '10px 2em',
});

const TveTitle = (props) => {
  return (
    <Container>
      <Wrapper>
        <Box>{props.pageName}</Box>
        <Box style={{ color: 'white', fontStyle: 'italic', fontWeight: 600 }}>
          C4I Training Virtual Environment &reg; 2024-R2.7
        </Box>
      </Wrapper>
    </Container>
  );
};

export default TveTitle;
