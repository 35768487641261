import React from 'react';
import {
  SimpleList,
  InfiniteList,
  InfinitePagination,
  useListContext,
  DatagridConfigurable,
  TextField,
  FunctionField,
  CreateButton,
  BooleanInput,
  downloadCSV,
  ExportButton,
  WithPermissions,
  TopToolbar,
  TextInput,
  ReferenceInput,
  SelectInput,
  SelectColumnsButton,
  usePermissions,
} from 'react-admin';
import SchedulePauseTveButton from '../Components/SchedulePauseTveButton';
import UnschedulePauseTveButton from '../Components/UnschedulePauseTveButton';
import ScheduleStartupTveButton from '../Components/ScheduleStartupTveButton';
import UnscheduleStartupTveButton from '../Components/UnscheduleStartupTveButton';
import ManageButton from '../Components/ManageButton';
import TveTitle from '../Components/TveTitle';
import jsonExport from 'jsonexport/dist';
import { useMediaQuery, Box, Card, Typography } from '@mui/material';
import { useGetList } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';

const InstructorMessage = () => {
  const { data, isLoading, error } = useGetList('tveapppropvalues', {});

  if (isLoading) {
    return <CircularProgress size="1rem" color="inherit" />;
  }

  const tvePropertyValue = data?.find(
    (item) => item.tveProp.tvePropKey === 'Global_Instructor_Message'
  );

  return (
    <span
      style={{
        color: 'red',
        backgroundColor: 'white',
        textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)',
      }}
    >
      <p>
        <strong>{tvePropertyValue?.tvePropValue}</strong>{' '}
      </p>
    </span>
  );
};

const exporter = (props) => {
  const tveClassListForExport = props.map((tveClass) => {
    // Omit all the fields inside the curly brackets
    const {
      tveClassId,
      name,
      description,
      startDate,
      endDate,
      startTime,
      endTime,
      tveTrainingLocation,
      tveTimeZone,
      course,
      tveClassStatus,
      instructor,
      startupActive,
      pauseActive,
      pauseTime,
      startupTime,
      id,
      courseId,
      totalStudents,
      totalInstructors,
      classType,
      ...tveClassListForExport
    } = tveClass;

    // Add fields
    tveClassListForExport.ClassId = tveClassId;
    tveClassListForExport.ClassName = name;
    tveClassListForExport.Location = tveTrainingLocation?.displayName;
    tveClassListForExport.StartDate = startDate;
    tveClassListForExport.EndDate = endDate;
    tveClassListForExport.Course = course.name;
    tveClassListForExport.Status = tveClassStatus.name;
    tveClassListForExport.Instructor = instructor.appUserAlias;
    tveClassListForExport.ClassType = classType;
    tveClassListForExport.StartTime = startupTime;
    tveClassListForExport.PauseTime = pauseTime;
    return tveClassListForExport;
  });

  jsonExport(tveClassListForExport, {}, (_err, csv) => {
    downloadCSV(csv, 'TveClasses');
  });
};

const CustomPagination = () => {
  const { total } = useListContext();
  return (
    <>
      <InfinitePagination />
      {total > 0 && (
        <Box position="sticky" bottom={0} textAlign="center">
          <Card
            elevation={2}
            sx={{ px: 2, py: 1, mb: 1, display: 'inline-block', backgroundColor: 'lightblue' }}
          >
            <Typography variant="body2">
              {total === 1 ? `${total} class` : `${total} classes`}
            </Typography>
          </Card>
        </Box>
      )}
    </>
  );
};

// Tie the appearance of the auto-start and auto-pause buttons to their applicable property
const BulkActionButtons = () => {
  const { data, isLoading, error } = useGetList('tveapppropvalues', {});

  if (isLoading) {
    return <CircularProgress size="1rem" color="inherit" />;
  }

  if (error) {
    return <div>error loading data</div>;
  }

  const autoStartFunctionality = data?.find(
    (item) => item.tveProp.tvePropKey === 'Global_Startup_Class_Schedule'
  )?.tvePropValue;

  const autoPauseFunctionality = data?.find(
    (item) => item.tveProp.tvePropKey === 'Global_Pause_Class_Schedule'
  )?.tvePropValue;

  const showAutoStartButtons = autoStartFunctionality === 'ON';
  const showAutoPauseButtons = autoPauseFunctionality === 'ON';

  if (!showAutoStartButtons && !showAutoPauseButtons) { return null };

  return (
    <>
      {showAutoStartButtons && (
        <>
          <ScheduleStartupTveButton />
          <UnscheduleStartupTveButton />
        </>
      )}
      {showAutoPauseButtons && (
        <>
          <SchedulePauseTveButton />
          <UnschedulePauseTveButton />
        </>
      )}
    </>
  );
};

const timestampToDate = (timestampstring) => {
  return new Date(timestampstring).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

const TveClassCreateButton = () => <CreateButton label="add class" variant="contained" />;

const ListActions = () => (
  <>
    <WithPermissions
      render={({ permissions }) => {
        switch (permissions) {
          case 'STUDENT':
          case 'INSTRUCTOR':
            return (
              <TopToolbar>
                <TveClassCreateButton />
                {/* <ExportButton
                  permissions={permissions}
                  sort={{ field: 'tveClassId', order: 'ASC' }}
                  exporter={exporter}
                  variant="contained"
                /> */}
                <SelectColumnsButton variant="contained" />
              </TopToolbar>
            );
          case 'DEVELOPER':
          case 'ADMIN':
            return (
              <TopToolbar>
                <TveClassCreateButton />
                {/* <ExportButton
                  permissions={permissions}
                  sort={{ field: 'tveClassId', order: 'ASC' }}
                  exporter={exporter}
                  variant="contained"
                /> */}
                <SelectColumnsButton variant="contained" />
              </TopToolbar>
            );
          default:
            return null;
        }
      }}
    />
  </>
);

const classFilters = [
  <BooleanInput
    sx={{ pl: 2, pb: 1 }}
    label="Show Classes for All Organizations"
    source="showAll"
    alwaysOn
  />,
  <BooleanInput
    sx={{ pl: 0, pb: 1 }}
    label="Hide Non-Instructing Classes"
    source="hideNonInstructing"
    alwaysOn
  />,
  <TextInput label="Id" source="tveClassId" alwaysOn sx={{ width: 75 }} />,
  <SelectInput
    label="Track"
    source="track"
    choices={INSTRUCTOR_TRACKS.map((filter) => ({ id: filter.trackId, name: filter.name }))}
    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
    alwaysOn
  />,
  <TextInput label="Instructor (CAC Name)" source="instructor.appUserAlias" alwaysOn />,
  <TextInput label="Training Location" source="tveTrainingLocation.displayName" alwaysOn />,
  <ReferenceInput source="tveClassStatus" reference="classstatuses" alwaysOn>
    <SelectInput label="Status" optionText="name" />
  </ReferenceInput>,
];

const classFiltersForInstructors = [
  <BooleanInput
    sx={{ pl: 2, pb: 1 }}
    label="Show Classes for All Organizations"
    source="showAll"
    alwaysOn
  />,
  <BooleanInput
    sx={{ pl: 0, pb: 1 }}
    label="Hide Non-Instructing Classes"
    source="hideNonInstructing"
    alwaysOn
  />,
  <TextInput label="Filter by Class Id" source="tveClassId" alwaysOn sx={{ width: 140 }} />,
  <TextInput label="Instructor (CAC Name)" source="instructor.appUserAlias" alwaysOn />,
  <TextInput label="Training Location" source="tveTrainingLocation.displayName" alwaysOn />,
  <ReferenceInput source="tveClassStatus" reference="classstatuses" alwaysOn>
    <SelectInput label="Status" optionText="name" />
  </ReferenceInput>,
];

const translateClassType = (classtype) => {
  if (classtype === 'I') return 'Instructing';
  if (classtype === 'N') return 'Non-Instructing';
};

const TveClassList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { permissions } = usePermissions();

  // if the user is an instructor, hide the track filter and instead only show the classes by the user's track (via the REST API call)
  const filters = permissions === 'INSTRUCTOR' ? classFiltersForInstructors : classFilters;

  return (
    <>
      <InstructorMessage />
      <InfiniteList
        pagination={<CustomPagination />}
        title={<TveTitle pageName="Classes" />}
        sort={{ field: 'tveClassId', order: 'DESC' }}
        actions={<ListActions />}
        filters={filters}
        filterDefaultValues={{ showAll: false }}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => `${record.startDate} - ${record.endDate}`}
            tertiaryText={(record) => record.tveClassStatus.name}
          />
        ) : (
          <DatagridConfigurable bulkActionButtons={<BulkActionButtons />} rowClick="edit" omit={['startupTime']}>
            <TextField source="tveClassId" label="Id" />
            <TextField source="name" label="Class Name" />
            <TextField source="track" label="Track" />
            <FunctionField
              label="Location"
              render={(record) =>
                record.tveTrainingLocation?.location === undefined
                  ? ``
                  : `${record.tveTrainingLocation.displayName}`
              }
              sortBy="tveTrainingLocation.displayName"
            />
            <FunctionField
              label="Start Date"
              render={(record) => timestampToDate(record.startDate)}
              sortBy="startDate"
            />
            <FunctionField
              label="End Date"
              render={(record) => timestampToDate(record.endDate)}
              sortBy="endDate"
            />
            <TextField source="course.name" label="Course" />
            <TextField source="tveClassStatus.name" label="Status" />
            <TextField source="instructor.appUserAlias" label="Instructor" />
            {/* <TextField source="totalStudents" label="Total Students" /> */}
            {/* <TextField source="totalInstructors" label="Total Instructors" /> */}
            <FunctionField
              label="Class Type"
              render={(record) => translateClassType(record.classType)}
              sortBy="classType"
            />
            <TextField source="startupTime" label="Start Time" />
            <TextField source="pauseTime" label="Pause Time" />
            <ManageButton />
          </DatagridConfigurable>
        )}
      </InfiniteList>
    </>
  );
};

export default TveClassList;
